import { gql } from '@apollo/client'
import React from 'react'
import { useFranchiseLocationsQuery } from '../../../../../generated/graphql'
import { cacheFirstFetchPolicyObj } from '../constants/cacheFirstFetchPolicyObj'
import { featureFlags } from '../../../../auth/FeatureFlagProvider'
import { useFeatureFlag } from '../../../../auth/hooks/useFeatureFlag'

gql`
  query FranchiseLocations {
    franchise {
      locations {
        id
        name
        country {
          code
        }
      }
    }
  }
`

export function useFranchiseLocations() {
  const limitFranchiseToParkCityIsEnabled = useFeatureFlag(featureFlags.limitFranchiseToParkCity).isEnabled

  const { data, ...franchiseLocationsQuery } = useFranchiseLocationsQuery(cacheFirstFetchPolicyObj)
  const franchiseLocationOptions = React.useMemo(
    () =>
      (data?.franchise?.locations ?? [])
        .map((x) => ({
          label: x.name,
          value: x.id,
          country: x.country.code,
        }))
        .filter((x) => !limitFranchiseToParkCityIsEnabled || x.label === 'Park City'),
    [data, limitFranchiseToParkCityIsEnabled]
  )
  return { franchiseLocationOptions, ...franchiseLocationsQuery }
}
