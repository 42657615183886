import * as React from 'react'
import { cacheFirstFetchPolicyObj } from '../constants/cacheFirstFetchPolicyObj'
import { gql } from '@apollo/client'
import { useFranchiseLocations } from './useFranchiseLocations'
import { FranchiseCountryEnum, useFranchiseProfileModalDataQuery } from '../../../../../generated/graphql'
import { isThisEnum } from '../../../../../typescript/guards/isThisEnum'
import { typedFalsyFilter } from '../../../../../typescript/guards/typedFalsyFilter'
import { useAccount } from '../../../../auth/hooks/useAccount'

gql`
  query FranchiseProfileModalData($country: FranchiseCountryEnum) {
    me_v2 {
      id
      name
      phone_number
      date_of_birth
      sex
    }
    franchise_user_profile {
      id
      zenoti_location_id
      zenoti_country
    }
    franchise(country: $country) {
      communication_preferences {
        receive_marketing_sms
        receive_marketing_email
        receive_transactional_sms
        receive_transactional_email
      }
    }
    lab_profile {
      ship_to_address
      ship_to_city
      ship_to_country
      ship_to_postal_code
      ship_to_state
    }
  }
`

export function useFranchiseProfileModalData(country?: FranchiseCountryEnum) {
  const email = useAccount().user?.email ?? ''
  const { franchiseLocationOptions } = useFranchiseLocations()
  const { data } = useFranchiseProfileModalDataQuery({ variables: { country }, ...cacheFirstFetchPolicyObj })
  const res = React.useMemo(() => {
    const id = data?.me_v2?.id
    const name = data?.me_v2?.name
    const phone_number = data?.me_v2?.phone_number
    const date_of_birth = data?.me_v2?.date_of_birth
    const franchise_user_profile = data?.franchise_user_profile
    const lab_profile = data?.lab_profile
    const labProfile = lab_profile?.[0]
    const profiles = franchise_user_profile
      ?.map((profile) => {
        const zenotiCountry = profile.zenoti_country
        if (!isThisEnum(zenotiCountry, FranchiseCountryEnum)) return null
        return {
          id: profile.id,
          preferredLocationId: profile.zenoti_location_id,
          preferredLocationName: franchiseLocationOptions.find((x) => x.value === profile.zenoti_location_id)?.label,
          country: zenotiCountry,
        }
      })
      .filter(typedFalsyFilter)

    const currentProfile = profiles?.find((x) => x.country === country) || profiles?.[0]

    const fullName = name ?? ''

    const [firstName = '', lastName = ''] = (fullName ?? '').split(' ')
    const phoneNumber = phone_number ?? ''

    const communicationPreferences = data?.franchise?.communication_preferences
    return {
      fullName,
      userId: id,
      firstName,
      lastName,
      phoneNumber,
      dateOfBirth: date_of_birth ?? '',
      address: labProfile?.ship_to_address ?? '',
      city: labProfile?.ship_to_city ?? '',
      country: isThisEnum(labProfile?.ship_to_country, FranchiseCountryEnum)
        ? labProfile.ship_to_country
        : FranchiseCountryEnum.US,
      postalCode: labProfile?.ship_to_postal_code ?? '',
      state: labProfile?.ship_to_state ?? '',
      email,
      preferredLocationId: currentProfile?.preferredLocationId ?? '',
      preferredLocationName: currentProfile?.preferredLocationName ?? '',
      marketingSms: !!communicationPreferences?.receive_marketing_sms,
      transactionalSms: !!communicationPreferences?.receive_transactional_sms,
      marketingEmails: !!communicationPreferences?.receive_marketing_email,
      transactionalEmails: !!communicationPreferences?.receive_transactional_email,
      isMultiCountryUser: !!profiles && profiles.length > 1,
    }
  }, [data, franchiseLocationOptions, country, email])
  return res
}
