import * as React from 'react'
import { useWordPressFranchiseLocationsDataQuery } from '../../../../../generated/franchise'
import { cacheFirstFetchPolicyObj } from '../constants/cacheFirstFetchPolicyObj'
import { typedFalsyFilter } from '../../../../../typescript/guards/typedFalsyFilter'
import { externalFranchiseUrlBase } from '../../../../../common/constants/routes'
import { NonNullableNonOptionalObject } from '../../../../../typescript/utility'

import { parsePhoneNumber } from 'libphonenumber-js'

function formatPhoneNumber(input: string | null) {
  const digital = input?.replace(/\D/g, '')
  if (!digital) return null
  // Parse and default to US if no country code is provided
  const phoneNumber = parsePhoneNumber(digital, 'US')

  if (!phoneNumber) return null

  const countryCode = phoneNumber.countryCallingCode
  const nationalNumber = phoneNumber.nationalNumber

  // Format national part: (541) 261-3244
  const formattedNationalNumber = nationalNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  const linkHrefPhoneNumber = phoneNumber.formatInternational().replace(/\s/g, '')

  return {
    formattedPhoneNumber: `+${countryCode} ${formattedNationalNumber}`,
    linkHrefPhoneNumber,
  }
}

export type UseWordPressFranchiseLocationsDataReturn = ReturnType<typeof useWordPressFranchiseLocationsData>

export function useWordPressFranchiseLocationsData() {
  const { data, loading } = useWordPressFranchiseLocationsDataQuery(cacheFirstFetchPolicyObj)

  const result = React.useMemo(() => {
    const processedLocationsData = (data?.locationsql?.nodes ?? []).map((x) => {
      const {
        asFranchiseName,
        asFranchiseStreetAddress,
        asFranchiseCity,
        asFranchiseState,
        asFranchisePostalCode,
        asFranchiseCountry,
        asFranchiseEmail,
        asFranchisePhone,
        asFranchiseGetDirectionsLink,
        asFranchiseWeeklyHours,
        asFranchiseId,
      } = x.locationInformation ?? {}

      const name = asFranchiseName || null
      const street = asFranchiseStreetAddress /* markdown */ || null
      const city = asFranchiseCity || null
      const state = asFranchiseState?.[0] || null
      const postalCode = asFranchisePostalCode || null
      const country = asFranchiseCountry || null
      const email = asFranchiseEmail || null
      const phone = asFranchisePhone || null
      const directionsLink = asFranchiseGetDirectionsLink || null

      const id = asFranchiseId?.[0] || null

      const order = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

      const hours = (asFranchiseWeeklyHours ?? [])
        .map((y) => {
          const open = y?.asFranchiseOh?.toUpperCase()
          const close = y?.asFranchiseCh?.toUpperCase()
          const day = y?.asFranchiseWhDay?.[0]?.toUpperCase()
          return open && close && day ? { day, time: `${open} - ${close}` } : null
        })
        .filter(typedFalsyFilter)
        .sort((a, b) => {
          const indexA = order.indexOf(a.day)
          const indexB = order.indexOf(b.day)
          if (indexA === -1) return 1
          if (indexB === -1) return -1
          return indexA - indexB
        })
        .reduce(
          (acc, { day, time }, i, arr) => {
            // if the item before this item has a different time, push a clean line item
            if (arr[i - 1]?.time !== time) {
              acc.push({ days: day, time })
              // if it is the same, check to see if it is different from the next item, and if so, append a hyphen and this day to the previous line item's days
            } else if (arr[i + 1]?.time !== time) {
              const previousResultItem = acc[acc.length - 1]
              if (previousResultItem) previousResultItem.days += ` - ${day}`
            } //if not, it means it is the same time as the previous and next, so we do nothing and let the next iteration handle finding the final matching time
            return acc
          },
          [] as { days: string; time: string }[]
        )

      const address =
        street && city && state && postalCode && country
          ? { streetAddress: street, city, state, postalCode, country }
          : null

      const phoneData = formatPhoneNumber(phone)
      return {
        address,
        name,
        email,
        directionsLink,
        hours,
        id,
        ...phoneData,
      }
    })

    const locationLinks = (data?.menuItem?.childItems?.edges ?? [])
      .map((x) => {
        const content = x.node.label
        const slug = x.node.url
        return content && slug ? { content, href: `${externalFranchiseUrlBase}${slug}` } : null
      })
      .filter(typedFalsyFilter)

    type ProcessedLocationDatum = (typeof processedLocationsData)[number]
    const pageData = processedLocationsData.reduce(
      (acc, x) => {
        const { name, address, hours, email, linkHrefPhoneNumber, formattedPhoneNumber, directionsLink, id } = x
        if (name && address && hours.length && email && linkHrefPhoneNumber && formattedPhoneNumber && directionsLink) {
          acc.push({ name, address, hours, email, linkHrefPhoneNumber, formattedPhoneNumber, directionsLink, id })
        }
        return acc
      },

      [] as (NonNullableNonOptionalObject<
        Pick<ProcessedLocationDatum, 'name' | 'address' | 'hours' | 'email' | 'directionsLink'>
      > &
        Pick<ProcessedLocationDatum, 'id' | 'formattedPhoneNumber' | 'linkHrefPhoneNumber'>)[]
    )
    const reviews = (data?.reviewsql?.nodes ?? [])
      .map((x) => {
        const content = x?.content
        const reviewerName = x?.reviewDetails?.reviewerName
        return content && reviewerName ? { content, reviewerName } : null
      })
      .filter(typedFalsyFilter)

    return { reviews, pageData, locationLinks }
  }, [data])
  return { loading: data && !loading, ...result }
}
