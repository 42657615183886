import React from 'react'
import { useSetModal } from '../../../../modal/modalState'
import dynamic from 'next/dynamic'
import { franchiseTabs } from '../constants/franchiseTabs'
import { useFranchiseProfileModalData } from './useFranchiseProfileModalData'
import { FranchiseCountryEnum } from '../../../../../generated/graphql'

const LazyFranchiseAccountModal = dynamic(() =>
  import('../organisms/EagerFranchiseAccountModal').then((x) => x.EagerFranchiseAccountModal)
)

export function useSetFranchiseAccountModal() {
  const setModal = useSetModal()

  const profileData = useFranchiseProfileModalData()

  return {
    setFranchiseAccountModal: React.useCallback(
      (modalTab: franchiseTabs = franchiseTabs.upgrades) =>
        setModal(
          <LazyFranchiseAccountModal
            name={profileData?.fullName ?? ''}
            modalTab={modalTab}
            country={profileData?.country ?? FranchiseCountryEnum.US}
          />
        ),
      [setModal, profileData]
    ),
    name: profileData?.fullName ?? '',
    firstName: profileData?.firstName ?? '',
    lastName: profileData?.lastName ?? '',
  }
}
