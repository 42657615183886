import clsx from 'clsx'
import React from 'react'
import dynamic from 'next/dynamic'
import { LazyLoadingSpinner } from '../../components/LazyLoadingSpinner'

const LazyLabelCtaLarge = dynamic(() => import('../typography/LabelCtaLarge').then((x) => x.LabelCtaLarge))
const LazyLabelCtaMedium = dynamic(() => import('../typography/LabelCtaMedium').then((x) => x.LabelCtaMedium))
const LazyLabelCtaSmall = dynamic(() => import('../typography/LabelCtaSmall').then((x) => x.LabelCtaSmall))

const LazyIconsAdd = dynamic(() => import('../../../assets/components/IconsAdd').then((x) => x.IconsAdd))
const LazyNavigationArrow = dynamic(() =>
  import('../../../assets/components/NavigationArrow').then((x) => x.NavigationArrow)
)

const elementsWithDisabledAttr = ['button', 'fieldset', 'input', 'optgroup', 'option', 'select', 'textarea']

// Define the types for intrinsic elements
type IntrinsicProps<K extends keyof JSX.IntrinsicElements> = Exclude<JSX.IntrinsicElements[K], 'className'>

// Define the type for custom components
type CustomComponentProps<T extends React.ElementType> = T extends React.ComponentType<infer P> ? P : never

type TypeOptions =
  | { primary?: true }
  | { secondary: true }
  | { tertiary: true }
  | { franchisePrimary: true }
  | { franchiseSecondary: true }
type IconOptions = { plus?: true } | { chevron?: true }
type SizeOptions = { large?: true } | { medium: true } | { small: true } | { xsmall: true }

// TODO could also make this have elementProps as required if any of the elementProps are required
type Props<T extends React.ElementType> = React.PropsWithChildren<
  { disabled?: boolean; loading?: boolean; stretch?: boolean } & TypeOptions &
    IconOptions &
    SizeOptions &
    (
      | {
          as?: T
          elementProps?: T extends keyof JSX.IntrinsicElements ? IntrinsicProps<T> : CustomComponentProps<T>
          children?: React.ReactNode
        }
      | { as?: T; elementProps?: never; children: React.ReactNode } // Custom components without specific props in the'never' case (shouldn't happen)>
    )
>

const styles = {
  large: (icon?: 'left' | 'right') =>
    clsx(
      'h-[52px] min-w-32 rounded-full py-4',
      icon === 'left' ? 'pl-5 pr-6' : icon === 'right' ? 'pl-6 pr-5' : 'px-5'
    ),
  medium: (icon?: 'left' | 'right') =>
    clsx(
      'h-[42px] min-w-24 rounded-full py-3',
      icon === 'left' ? 'pl-4 pr-5' : icon === 'right' ? 'pl-5 pr-4' : 'px-4'
    ),
  small: (icon?: 'left' | 'right') =>
    clsx(
      'h-[30px] min-w-16 rounded-full px-4 py-3',
      icon === 'left' ? 'pl-2 pr-3' : icon === 'right' ? 'pl-3 pr-2' : 'px-2'
    ),
  xsmall: (icon?: 'left' | 'right') =>
    clsx('h-6 min-w-12 rounded-lg py-1', icon === 'left' ? 'pl-1 pr-2' : icon === 'right' ? 'pl-2 pr-1' : 'px-1.5'),
}

export function Clickable<T extends React.ElementType>({
  children,
  loading,
  disabled,
  stretch,
  as: asProp = 'button' as T,
  elementProps,
  ...rest
}: Props<T>) {
  const iconSide = 'chevron' in rest ? 'right' : 'plus' in rest ? 'left' : undefined

  const isDisabled = loading || disabled

  const sizeClasses = (
    'large' in rest ? styles.large : 'medium' in rest ? styles.medium : 'small' in rest ? styles.small : styles.xsmall
  )(iconSide)

  const TextComponent =
    'large' in rest
      ? LazyLabelCtaLarge
      : // both medium and small use label cta medium
        'medium' in rest || 'small' in rest
        ? LazyLabelCtaMedium
        : // only xsmall uses label cta small
          LazyLabelCtaSmall

  const iconProps = {
    size: 18,
    groupClassName: `${
      'primary' in rest
        ? `!stroke-grayscalewhite group-hover:!stroke-grayscalewhite group-active:!stroke-grayscalewhite group-disabled:!stroke-grayscale000`
        : 'secondary' in rest
          ? `!stroke-primary800 group-hover:!stroke-primary900 group-active:!stroke-primary900 group-disabled:!stroke-grayscale400`
          : 'tertiary' in rest
            ? '!stroke-primary700 group-hover:!stroke-primary800 group-active:!stroke-primary900 group-disabled:!stroke-grayscale400'
            : 'franchisePrimary' in rest
              ? `!stroke-grayscalewhite group-hover:!stroke-grayscalewhite group-active:!stroke-grayscalewhite group-disabled:!stroke-grayscale000`
              : 'franchiseSecondary' in rest
                ? '!stroke-yamabukiGold group-hover:!stroke-hotterButter group-active:!stroke-orangePepper group-disabled:!stroke-grayscale400'
                : ''
    }`,
  }

  const cursorClasses = isDisabled ? (loading ? 'cursor-progress' : 'cursor-not-allowed') : 'cursor-pointer'

  const Tag = asProp

  const { className, ...nonClassNameElementProps } = (elementProps ?? {}) as typeof elementProps & {
    className?: string
  } // !! can probably fix this but in a rush. className should always be a possible prop
  return (
    <>
      <Tag
        {...(typeof asProp === 'string' && elementsWithDisabledAttr.includes(asProp) && { disabled: isDisabled })}
        className={clsx(
          stretch ? 'w-full' : 'w-fit',
          // base styles
          'group relative flex flex-shrink appearance-none items-center justify-center gap-0.5 outline-none transition',
          sizeClasses,
          cursorClasses,
          // !!do not do variable interpolation in tailwind classnames or the compiler will miss them!!
          // background color classes
          'primary' in rest
            ? `bg-primary700 hover:bg-primary800 active:bg-primary900 disabled:bg-grayscale300`
            : 'secondary' in rest
              ? `bg-primary100 hover:bg-primary200 active:bg-primary300 disabled:bg-grayscale100`
              : 'tertiary' in rest
                ? 'bg-transparent hover:bg-transparent active:bg-transparent disabled:bg-transparent'
                : 'franchisePrimary' in rest
                  ? 'bg-raichuOrange hover:bg-hotterButter active:bg-orangePepper disabled:bg-grayscale300'
                  : 'franchiseSecondary' in rest
                    ? 'bg-blankCanvas hover:bg-tunisianStone active:bg-whippedApricot disabled:bg-grayscale100'
                    : '',
          className
        )}
        {
          ...(nonClassNameElementProps as any) /* this seems risky, but the typings are 100% correct for each as when feeding the props in. tried many different variations, and could not get these typings to work. i also noticed the typings are less accurate in styled components using the as="" prop than here, so i assume they also could not get the type inference to work. in our version, when rendering the component, the typings are correct for what element props can be fed in based on what you define the as as. one enhancement would be to allow feeding in either a as or a component, and using the intrinsic props if feeding in a as, and use the parameters of that actual component if feeding in a custom component */
        }
      >
        {'plus' in rest && <LazyIconsAdd {...iconProps} />}
        <TextComponent
          className={`${
            'primary' in rest
              ? `text-grayscalewhite group-hover:text-grayscalewhite group-active:text-grayscalewhite group-disabled:text-grayscale000`
              : 'secondary' in rest
                ? `text-primary800 group-hover:text-primary900 group-active:text-primary900 group-disabled:text-grayscale400`
                : 'tertiary' in rest
                  ? 'text-primary700 group-hover:text-primary800 group-active:text-primary900 group-disabled:text-grayscale400'
                  : 'franchisePrimary' in rest
                    ? '!font-oswald text-grayscalewhite group-hover:text-grayscalewhite group-active:text-grayscalewhite group-disabled:text-grayscale000'
                    : 'franchiseSecondary' in rest
                      ? '!font-oswald text-yamabukiGold group-hover:text-hotterButter group-active:text-orangePepper group-disabled:text-grayscale400'
                      : ''
          }`}
        >
          {children}
        </TextComponent>
        {'chevron' in rest && <LazyNavigationArrow orientation="right" {...iconProps} />}

        {loading && <LazyLoadingSpinner isSmall styles={{ position: 'absolute' }} />}
      </Tag>
    </>
  )
}
